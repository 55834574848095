<template>
  <div></div>
</template>

<script>
import liff from "@line/liff";
import axios from "axios";
import LiffChecker from "@/utils/LiffChecker";
const DEFAULT_MESSAGE = {
  "messages": [
    {
      "type": "flex",
      "altText": "This is a Flex Message",
      "contents": {
        "type": "carousel",
        "contents": [
          {
            "hero": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "size": "full",
                      "aspectRatio": "4:3",
                      "aspectMode": "cover",
                      "url": "https://storage.googleapis.com/waltily-dev/temp/snoopy%203.4.png"
                    }
                  ],
                  "paddingAll": "0px"
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "text",
                      "text": "AAAAAAA",
                      "color": "#ffffff",
                      "align": "center",
                      "size": "xs",
                      "offsetTop": "4px"
                    }
                  ],
                  "position": "absolute",
                  "cornerRadius": "15px",
                  "backgroundColor": "#ff334b",
                  "height": "25px",
                  "offsetTop": "10px",
                  "offsetStart": "10px",
                  "paddingStart": "5px",
                  "paddingEnd": "5px"
                }
              ],
              "paddingAll": "0px"
            },
            "body": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "size": "lg",
                      "weight": "bold",
                      "type": "text",
                      "text": "POYA Buy 寶雅線上買",
                      "align": "center",
                      "color": "#DE006F",
                      "wrap": true
                    }
                  ]
                }
              ],
              "paddingAll": "10px",
              "paddingTop": "10px",
              "paddingBottom": "0px",
              "paddingStart": "15px"
            },
            "styles": {
              "hero": {
                "backgroundColor": "#FFFFFF"
              }
            },
            "type": "bubble",
            "footer": {
              "spacing": "sm",
              "flex": 0,
              "type": "box",
              "contents": [
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "style": "link",
                  "type": "button",
                  "height": "sm",
                  "action": {
                    "type": "uri",
                    "label": "POYA Buy 寶雅線上買",
                    "uri": "https://www.poyabuy.com.tw/"
                  },
                  "color": "#5B82DB"
                },
                {
                  "type": "spacer",
                  "size": "sm"
                }
              ],
              "layout": "vertical"
            }
          },
          {
            "type": "bubble",
            "body": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "image",
                  "url": "https://uc.udn.com.tw/photo/2022/08/08/0/18239674.jpg",
                  "size": "full",
                  "aspectMode": "cover",
                  "aspectRatio": "1:2",
                  "gravity": "center"
                }
              ],
              "paddingAll": "0px",
              "action": {
                "type": "uri",
                "label": "action",
                "uri": "http://linecorp.com/"
              }
            }
          },
          {
            "type": "bubble",
            "body": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "image",
                  "url": "https://uc.udn.com.tw/photo/2022/08/07/0/18230409.jpg",
                  "size": "full",
                  "aspectMode": "cover",
                  "aspectRatio": "1:1",
                  "gravity": "center"
                },
                {
                  "type": "image",
                  "url": "https://uc.udn.com.tw/photo/2022/08/07/0/18230409.jpg",
                  "size": "full",
                  "aspectMode": "cover",
                  "aspectRatio": "1:1",
                  "gravity": "center"
                }
              ],
              "paddingAll": "0px",
              "action": {
                "type": "uri",
                "label": "action",
                "uri": "http://linecorp.com/"
              }
            }
          },
          {
            "type": "bubble",
            "body": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://uc.udn.com.tw/photo/2022/08/09/realtime/18252735.jpg",
                      "size": "full",
                      "aspectRatio": "1.5:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://uc.udn.com.tw/photo/2022/08/09/realtime/18252735.jpg",
                      "size": "full",
                      "aspectRatio": "1.5:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://uc.udn.com.tw/photo/2022/08/09/realtime/18252735.jpg",
                      "size": "full",
                      "aspectRatio": "1.5:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                }
              ],
              "paddingAll": "0px",
              "action": {
                "type": "uri",
                "label": "action",
                "uri": "http://linecorp.com/"
              }
            }
          },
          {
            "type": "bubble",
            "body": {
              "type": "box",
              "layout": "vertical",
              "contents": [
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png",
                      "size": "full",
                      "aspectRatio": "2:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png",
                      "size": "full",
                      "aspectRatio": "2:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png",
                      "size": "full",
                      "aspectRatio": "2:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                },
                {
                  "type": "box",
                  "layout": "vertical",
                  "contents": [
                    {
                      "type": "image",
                      "url": "https://scdn.line-apps.com/n/channel_devcenter/img/fx/01_1_cafe.png",
                      "size": "full",
                      "aspectRatio": "2:1",
                      "aspectMode": "cover"
                    }
                  ],
                  "action": {
                    "type": "uri",
                    "label": "action",
                    "uri": "http://linecorp.com/"
                  }
                }
              ],
              "paddingAll": "0px"
            }
          }
        ]
      }
    }
  ]
};

export default {
  mounted() {
    this.redirect()
  },
  methods: {
    async redirect() {
      const orgCode = this.$route.params.org_code;
      const checker = new LiffChecker(orgCode);
      await checker.initLiff();
      // Get Template Data
      try {
        let url = process.env.VUE_APP_API_BASE_URL + `/${orgCode}/liff/share/products`;

        let response = await axios.get(url);

        if (response.data.data) {
          console.log(response.data.data);
          // let message = JSON.parse(response.data.data);
          let message = DEFAULT_MESSAGE
          console.log(DEFAULT_MESSAGE)
          console.log(message['messages'])
          if (liff.isApiAvailable('shareTargetPicker')) {
            liff.shareTargetPicker(message['messages'], { isMultiple: true }).then(function (res) {
              console.log(res)
              if (res) {
                console.log('sent');
                window.location.href = response.data.data.line_oa_url
              } else {
                console.log('user canceled sharing.');
              }
            }).catch(function (error) {
              console.error(error);
            });
          } else {
            alert('此 LINE 帳號尚未開啟 Share Target Picker 功能');
            console.log('share target picker api not available.');
          }
        } else {
          throw false
        }
      } catch (e) {
        alert('分享失敗');
        console.error(e);
      }
    }
  }
}
</script>
